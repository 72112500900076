<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">
            {{ $t("searchModule.Query_Table") }}
          </div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item
                :label="$t('searchModule.plate_number')"
                prop="plateNumber"
              >
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="
              $route.meta.authority.button.add ||
              $route.meta.authority.button.export
            "
          ></div>
          <div
            class="col_box h44"
            v-if="
              $route.meta.authority.button.add ||
              $route.meta.authority.button.export
            "
          >
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="$route.meta.authority.button.add"
                @click="addPush"
                >{{ $t("button.addto") }}</el-button
              >
            </div>
            <div class="col_right mbd4">
              <el-button
                type="primary"
                plain
                icon=""
                @click="exportFile"
                v-if="$route.meta.authority.button.export"
                ><i class="iconfont icon-daochu"></i
                >{{ $t("button.export") }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="50"></el-table-column> -->
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            :index="indexMethod"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="content"
            align="center"
            :label="$t('searchModule.remarks')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="creatorName"
            align="center"
            :label="$t('list.creator')"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createdTime"
            align="center"
            :label="$t('searchModule.Creation_time')"
            width="170"
          >
          </el-table-column>
          <el-table-column
            :label="this.$t('list.operation')"
            align="center"
            width="80"
            v-if="
              $route.meta.authority.button.edit ||
              $route.meta.authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" style="padding: 0" size="small"
                  >操作<i class="el-icon-arrow-down" style="margin-left: 4px"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="$route.meta.authority.button.update"
                    command="b"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="$route.meta.authority.button.delete"
                    command="c"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button type="text" @click="$router.push({path:'/specialPlatenum-AE', query: scope.row})">修改</el-button>
                            <el-button type="text" @click="deleteItem(scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <!-- <div class="left export" @click="resetPsd">
              重置密码
            </div> -->
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
export default {
  name: "specialPlatenum",
  data() {
    //   let checkParkNum = (rule, value, callback) => {
    //   let len = value && value.length;
    //  if (!value) {
    //    callback();
    //   } else if (len < 3) {
    //    callback(new Error('至少输入三位车牌号'))
    //   } else if (len > 8) {
    //    callback(new Error('车牌号不能大于8位'))
    //   } else {
    //     callback()
    // //   let c = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Za-z]{1}[A-Za-z]{1}[A-Za-z0-9]{4}[A-Za-z0-9挂学警港澳]{2}$/.test(value)
    // //   let t = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Za-z]{1}[A-Za-z]{1}[A-Za-z0-9]{4}[A-Za-z0-9挂学警港澳]{1}$/.test(value)
    // //  if (c || t) {
    // //     callback();
    // //   } else {
    // //     callback(new Error('车牌号格式不正确'))
    // //   }
    //   }
    // }
    return {
      upData: {
        "Session-Id": sessionStorage.token,
      },
      selection: [],
      tenantList: [],
      plateNumber: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      header: {
        "Session-Id": sessionStorage.token,
      },
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
      ],
      tableData: [],
      //  dateRange: [new Date(startTime), new Date(endTime)],
      formInline: {
        carOwner: "",
        carId: "",
        operationId: "",
      },
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    addPush() {
      this.$router.push({
        path: "/blacklist-AE",
      });
    },
    handleCommand(cmd, data) {
      if (cmd == "b") {
        this.$router.push({
          path: "/blacklist-AE",
          query: data,
        });
      }
      if (cmd == "c") {
        this.deleteItem(data);
      }
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    handleSelect(item) {
      this.formInline.carId = item.carId;
      this.page = 1;
    },
    indexMethod(index) {
      return index + 1;
    },
    exportFile() {
      let opt = {};
      opt = {
        carId: this.formInline.carId,
        carOwner: this.formInline.carOwner,
        operationId: this.formInline.operationId,
      };
      exportExcelNew("/acb/2.0/specialplate/export", opt);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(data[1], "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    // 搜索
    searchData() {
      if (!this.formInline.carId) this.plateNumber = "";
      this.loading = true;
      this.$axios
        .get("/acb/2.0/blacklist/getPageList", {
          data: {
            carId: this.formInline.carId,
            carOwner: this.formInline.carOwner,
            operationId: this.formInline.operationId,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    deleteItem({ blacklistId }) {
      this.$confirm("确定删除该数据?", this.$t("pop_up.Tips"), {
        confirmButtonText: this.$t("pop_up.Determine"),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //          this.$axios.post("/acb/2.0/blacklist/deleteById/" + blacklistId).then(res => {
          this.$axios
            .post("/acb/2.0/blacklist/deleteById/", {
              data: {
                blacklistId: blacklistId,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "删除成功！",
                });
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t("pop_up.Tips"), {
                  confirmButtonText: this.$t("pop_up.Determine"),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  components: {},
  activated() {
    this.searchData();
  },
  created() {
    this.getTenantList();
  },
  updated() {},
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding 22px 22px 0
    // margin-bottom 20px
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
